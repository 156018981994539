import React from 'react';
import PropTypes from 'prop-types';
import { renderRoutes } from 'react-router-config';

import MicroFrontend from './Containers/MicroFrontend';
import Home from './Components/Home';
import Config from './Config';

/*
  For documentation on deeply nested routes refer here:
  https://github.com/reacttraining/react-router/tree/master/packages/react-router-config
*/
const Root = ({ route }) => (
  <div>
    {/* child routes won't render without this */}
    {renderRoutes(route.routes)}
  </div>
);

const CompanyMicroFrontend = ({ history }) => (
  <MicroFrontend
    history={history}
    host={Config.reactApp.COMPANY_MICROFRONTEND_URL}
    name="CompanyMicroFrontend"
  />
);

const PhysicalLocationMicroFrontend = ({ history }) => (
  <MicroFrontend
    history={history}
    host={Config.reactApp.PHYSICAL_LOCATION_MICROFRONTEND_URL}
    name="PhysicalLocationMicroFrontend"
  />
);

export const routes = [{
  component: Root,
  routes: [
    {
      path: '/',
      exact: true,
      component: Home
    },
    {
      path: '/company',
      component: CompanyMicroFrontend,
      exact: false
    },
    {
      path: '/physical-location',
      component: PhysicalLocationMicroFrontend,
      exact: false
    }
  ]
}];

Root.propTypes = {
  route: PropTypes.object.isRequired
};

CompanyMicroFrontend.propTypes = {
  history: PropTypes.object.isRequired
};

PhysicalLocationMicroFrontend.propTypes = {
  history: PropTypes.object.isRequired
};
