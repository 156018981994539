import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import commonEn from 'componentlibrary/dist/translations/en.json';
import appEn from './en.json'; // english

const en = { ...commonEn, ...appEn };

export const SELECT_LOCALE = 'i18nextLng';

function initialiseI18nModule() {
  i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      fallbackLng: {
        default: ['en']
      },
      debug: false,
      interpolation: {
        escapeValue: false // not needed for react!!
      },
      detection: {
      // order and from where user language should be detected
        order: ['localStorage', 'navigator', 'querystring', 'cookie', 'htmlTag', 'path', 'subdomain'],
        lookupLocalStorage: SELECT_LOCALE,
        caches: ['localStorage']
      },
      // lng: 'es', // If you want to enforce a default language
      // react i18next special options (optional)
      react: {
        wait: false,
        bindI18n: 'languageChanged loaded',
        bindStore: 'added removed',
        nsMode: 'default'
      },
      resources: {
      // add all resource languages here
        en
      }
    }).on('languageChanged', (lang) => {
      document.documentElement.lang = lang;
    });
}

export function clearLocaleSelection() {
  localStorage.removeItem(SELECT_LOCALE);
  initialiseI18nModule();
}

initialiseI18nModule();

export default i18n;
