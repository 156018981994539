export const hashedLanguageOptions = {
  en: {
    translationKey: 'language:english',
    key: 'en'
  }
};

export const languageOptions = [
  hashedLanguageOptions.en
];

// This method will return the language option in our defined list that first
// match with the input languages.
//
// The input languages array should be taken from i18next.languages property.
// The array is populated with current selected language code, followed by less-specific
// versions of that code and then followed by the list of fallback languages.
export function getCurrentLanguage(languages) {
  if (!languages) {
    return null;
  }

  for (let i = 0; i < languages.length; i++) {
    if (hashedLanguageOptions[languages[i]]) {
      return hashedLanguageOptions[languages[i]];
    }
  }
  // Return null if not found
  return null;
}
